jQuery(document).ready(function() {

  // check if on mobile
  function isMobile() {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
          return true;
        } else {
          return false;
        }
  }

  // sticky nav js
  jQuery(window).scroll(function() {
    if(jQuery(window).scrollTop() >= 106) {
      jQuery('header.top-navigation').removeClass('not-scrolled');
      jQuery('header.top-navigation').addClass('scrolled');
      jQuery('header.top-navigation').find('.navbar-brand').find('img').attr('src', custom_theme_url + '/dist/images/bt-logo-black.svg');
    } else {
      jQuery('header.top-navigation').removeClass('scrolled');
      jQuery('header.top-navigation').addClass('not-scrolled');
      jQuery('header.top-navigation').find('.navbar-brand').find('img').attr('src', custom_theme_url + '/dist/images/bt-logo-white.svg');
    }
  });

  // open top navigation dropdowns on mobile
  jQuery('header.top-navigation').find('li.dropdown').on('click', function() {
    if(jQuery(this).hasClass('clicked')) {
      jQuery(this).removeClass('clicked');
    } else {
      jQuery(this).addClass('clicked');
    }
  });

  // stats boxes square
  jQuery('.stats-card-wrapper').height(jQuery('.stats-card-wrapper').width());

  // slick init
  jQuery('.slick-wrapper').each(function(index, value) {
    jQuery(value).slick({
      appendArrows: jQuery(value).parents('section').find('.navigation.slick'),
      prevArrow: '<span class="slick-arrow prev"><span class="fa fa-angle-left"></span></span>',
      nextArrow: '<span class="slick-arrow next"><span class="fa fa-angle-right"></span></span>',
      appendDots: jQuery(value).parents('section').find('.navigation.slick'),
      cssEase: 'ease-in-out',
      responsive: [{
        breakpoint: 480,
        settings: {
          autoplay: true,
          arrows: false,
          dots: false,
          centerMode: false,
          centerPadding:'0px',
          slidesToShow:1,
          slidesToScroll:1,
          adaptiveHeight: true
        }
      }]
    });
  });

  if(jQuery('#home-campaigns-slider').get().length>0) {
    fetchPosts('campaign', null, ajaxObj.homeCampaignCount, 'date', 'DESC', null, 0, '', function(response) {
      if(response!=null) {
        jQuery.each(response, function(key, value) {
          var post = document.createElement('div');
          jQuery(post).addClass('slick-slide');
          post.setAttribute('id', 'post-' + value.ID);
          var featImg = value.post_feat_video_url==null ? value.post_thumbnail_url : value.post_feat_video_url;
          jQuery(post).append('<a href="' + value.permalink + '" class="slick-card"><div class="front"><div class="bg-img" style="background-image: url(' + featImg + ');"></div><div class="title title-font">' + value.post_title + '</div></div><div class="details"><div class="title">' + value.post_title + '</div><div class="desc">' + value.post_excerpt + '</div><span class="cta-icon"><span class="material-right-icon sm white"></span></span></div></a>');
          jQuery('#home-campaigns-slider').slick('slickAdd', post);
        });
      }
    });
  }

  // our story timeline slides of same height
  if((jQuery('.cover-slider-wrapper').get().length>0) && (!isMobile())) {
    var maxHeight = 0;
    jQuery('.slick-wrapper .slide').each(function(index, value) {
      maxHeight = Math.max(maxHeight, jQuery(value).height());
    });
    jQuery('.slick-wrapper .slide').height(maxHeight);
  }

  // our work blocks of same maxHeight
  if((jQuery('.all-stats-wrapper.icon-n-data').get().length>0) && (!isMobile())) {
    var maxHeight = 0;
    jQuery('.slick-wrapper .stats-card').each(function(index, value) {
      maxHeight = Math.max(maxHeight, jQuery(value).height());
    });
    jQuery('.slick-wrapper .stats-card').height(maxHeight);
  }

  jQuery('[data-trigger=campaign-filter]').click(function() {
    fetchPosts('campaign', null, 5, 'date', 'DESC', null, 0, '_campaign_focus_areas,' + jQuery(this).attr("data-focus-area") + ',LIKE', function(response) {
      if(response!=null) {
        jQuery.each(response, function(key, value) {
          var post = document.createElement('div');
          jQuery(post).addClass('slick-slide');
          post.setAttribute('id', 'post-' + value.ID);
          var featImg = value.post_feat_video_url==null ? value.post_thumbnail_url : value.post_feat_video_url;
          jQuery(post).append('<a href="' + value.permalink + '" class="slick-card"><div class="front"><div class="bg-img" style="background-image: url(' + featImg + ');"></div><div class="title title-font">' + value.post_title + '</div></div><div class="details"><div class="title">' + value.post_title + '</div><div class="desc">' + value.post_excerpt + '</div><span class="cta-icon"><span class="material-right-icon sm white"></span></span></div></a>');
          jQuery('#home-campaigns-slider').slick('slickAdd', post);
        });
      }
    });
    jQuery('#' + jQuery(this).attr('data-target')).slick('removeSlide', null, null, true);
  });

  // slick init for blog post image gallery
  jQuery('.blog-post-content-wrapper .gallery').each(function(index, value) {
    jQuery(value).after('<div class="text-center"><div class="navigation slick"></div></div>');
    jQuery(value).slick({
      adaptiveHeight: true,
      appendArrows: jQuery(value).parent().find('.navigation.slick'),
      appendDots: jQuery(value).parent().find('.navigation.slick'),
      centerMode: true,
      centerPadding: '30px',
      dots: true,
      prevArrow: '<span class="slick-arrow prev"><span class="fa fa-angle-left"></span></span>',
      nextArrow: '<span class="slick-arrow next"><span class="fa fa-angle-right"></span></span>',
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 480,
        settings: {
          autoplay: true,
          arrows: false,
          dots: false,
          centerMode: false,
          centerPadding:'0px',
          slidesToShow:1,
          slidesToScroll:1
        }
      }]
    });
  });

  // campaign filter toggle
  jQuery('[data-trigger=filter-toggle] .filter-icon').click(function() {
    if(jQuery(this).parent().hasClass('before')) {
      jQuery(this).parent().removeClass('before');
      jQuery(this).parent().addClass('after');
    } else if(jQuery(this).parent().hasClass('after')) {
      jQuery(this).parent().removeClass('after');
      jQuery(this).parent().addClass('before');
    }
  });

  // subscribe submit by icon
  jQuery('.subscribe-wrapper form .cta-icon, .widget_subscribe_widget .cta-icon').click(function() {
    jQuery(this).closest('form').submit();
  });

  if(jQuery('.ajax-blogs-wrapper').get().length>0) {
    fetchPosts('post', null, 3, 'date', 'DESC', null, 0, '', function(response) {
      if(response!=null) {
        addPosts(response);
      } else {
        jQuery('.ajax-blogs-wrapper').parent().find('.load-more-btn').fadeOut();
        jQuery('.ajax-blogs-wrapper').append('<span class="finished animated fadeInUp"><div class="text">That\'s it for now. Stay tuned for more.</div><div class="line"></div></span>');
      }
    });
  }

  function addPosts(response) {
    var _this = jQuery('.ajax-blogs-wrapper');
    if(jQuery(_this).hasClass('empty')) {
      jQuery(_this).removeClass('empty');
      jQuery(_this).find('.custom-loader').fadeOut();
      jQuery(_this).parent().find('.load-more-btn').fadeIn().css('display', 'block');
    }
    jQuery.each(response, function(key, value) {
      var post = document.createElement('div');
      jQuery(post).addClass('blog-card animated fadeInUp');
      post.setAttribute('id', 'post-' + value.ID);
      var featImg = value.post_feat_video_url==null ? value.post_thumbnail_url : value.post_feat_video_url;
      jQuery(post).append('<a href="' + value.permalink + '" class="feat-img" style="background-image: url(' + featImg + ');"></a><div class="details"><div class="categories-wrapper">In&nbsp;</div><a href="' + value.permalink + '" class="title">' + trimAndAddEllipsis(value.post_title, 75) + '</a><div class="meta-wrapper"><span class="author">By <strong>' + value.post_author + '</strong></span><span class="separator"></span><span class="date">' + value.post_date + '</span></div><a href="' + value.permalink + '" class="excerpt">' + trimAndAddEllipsis(value.post_excerpt, 150) + '</a></div>');
      var ctr=0;
      jQuery.each(value.categories, function(catKey, catValue) {
        ctr++;
        if(ctr!=Object.keys(value.categories).length) {
          jQuery(post).find('.categories-wrapper').append('<a href="' + catValue.cat_link + '" class="category">' + catValue.cat_name + '</a>,&nbsp;');
        } else {
          jQuery(post).find('.categories-wrapper').append('<a href="' + catValue.cat_link + '" class="category">' + catValue.cat_name + '</a>');
        }
      });
      jQuery(_this).append(post);
      jQuery(_this).parent().find('.load-more-btn').html('Load More').css('width', '135px');
    });
  }

  function fetchPosts(postType, postCategory, postCount, postsOrderBy, postsOrder, postMetaKey, postOffset, metaQuery, handleData) {
    jQuery.ajax({
      url: ajaxObj.ajaxUrl,
      type: 'POST',
      data: {
        action: 'fetch_posts',
        js_ajax_call: true,
        post_type: postType,
        category: postCategory,
        no_of_posts: postCount,
        order_by: postsOrderBy,
        order: postsOrder,
        meta_key: postMetaKey,
        offset: postOffset,
        meta_query: metaQuery
      },
      success: function(data) {
        handleData(data);
      }
    });
  }

  function trimAndAddEllipsis(textString, noOfChar) {
    if(textString.length > noOfChar) {
      return textString.substring(0, noOfChar) + '...';
    } else {
      return textString;
    }
  }

  jQuery('[data-trigger=fetch-posts]').on('click', function() {
    if(jQuery(this).attr('data-source')=='filter') {
      jQuery(this).closest('.dropdown-menu').find('li.active').removeClass('active');
      jQuery(this).parent('li').addClass('active');
      resetWrapper(jQuery(this));
      var criteriaResponse = createQuery(jQuery(this));
      fetchPosts('post', criteriaResponse.postCategory, 3, criteriaResponse.postsOrderBy, criteriaResponse.postsOrder, criteriaResponse.postMetaKey, 0, '', function(response) {
        if(response!=null) {
          addPosts(response);
        } else {
          jQuery('.ajax-blogs-wrapper').find('.custom-loader').fadeOut();
          jQuery('.ajax-blogs-wrapper').append('<span class="finished animated fadeInUp"><div class="text">That\'s it for now. Stay tuned for more.</div><div class="line"></div></span>');
        }
      });
    } else if(jQuery(this).attr('data-source')=='load-more') {
      jQuery(this).append('&nbsp;&nbsp;<span class="fa fa-circle-o-notch fa-spin"></span>').css('width', '150px');
      var postOffset = jQuery(this).parent().find('.ajax-blogs-wrapper').find('.blog-card').length;
      var criteriaResponse = createQuery(jQuery(this));
      fetchPosts('post', criteriaResponse.postCategory, 3, criteriaResponse.postsOrderBy, criteriaResponse.postsOrder, criteriaResponse.postMetaKey, postOffset, '', function(response) {
        if(response!=null) {
          addPosts(response);
        } else {
          jQuery('.ajax-blogs-wrapper').parent().find('.load-more-btn').fadeOut();
          jQuery('.ajax-blogs-wrapper').append('<span class="finished animated fadeInUp"><div class="text">That\'s it for now. Stay tuned for more.</div><div class="line"></div></span>');
        }
      });
    }
  });

  function resetWrapper(fetchPostsButton) {
    var ajaxBlogsWrapper = fetchPostsButton.closest('.update-centre-wrapper').find('.ajax-blogs-wrapper');
    ajaxBlogsWrapper.addClass('empty');
    ajaxBlogsWrapper.parent().find('.load-more-btn').fadeOut();
    var loader = document.createElement('span');
    jQuery(loader).addClass('custom-loader animated fadeInUp');
    jQuery(loader).append('<span class="fa fa-circle-o-notch fa-spin"></span>');
    ajaxBlogsWrapper.html(loader);
    ajaxBlogsWrapper.attr('data-criteria', fetchPostsButton.attr('data-criteria'));
    if(fetchPostsButton.attr('data-criteria')=='category') {
      ajaxBlogsWrapper.attr('data-category', fetchPostsButton.text());
    }
  }

  function createQuery(fetchPostsButton) {
    var ajaxBlogsWrapper = fetchPostsButton.closest('.update-centre-wrapper').find('.ajax-blogs-wrapper');
    var postsOrderBy, postsOrder, postMetaKey, postCategory;
    switch (ajaxBlogsWrapper.attr('data-criteria')) {
      case 'newest-first':
        postsOrderBy = 'date';
        postsOrder = 'DESC';
        postMetaKey = null;
        postCategory = null;
        break;
      case 'oldest-first':
        postsOrderBy = 'date';
        postsOrder = 'ASC';
        postMetaKey = null;
        postCategory = null;
        break;
      case 'most-popular':
        postsOrderBy = 'meta_value_num';
        postsOrder = 'DESC';
        postMetaKey = '_post_popularity';
        postCategory = null;
        break;
      case 'category':
        postsOrderBy = 'date';
        postsOrder = 'DESC';
        postMetaKey = null;
        postCategory = ajaxBlogsWrapper.attr('data-category');
        break;
    }
    var query = {"postsOrderBy": postsOrderBy, "postsOrder": postsOrder, "postMetaKey": postMetaKey, "postCategory": postCategory};
    return query;
  }

  // convert shortcode data to DOM
  if(jQuery('.location-box-wrapper').get().length>0) {
    jQuery('.location-box-wrapper').each(function(index, value) {
      var telephoneInput = jQuery(value).attr('data-telephone');
      var faxInput = jQuery(value).attr('data-fax');
      var emailInput = jQuery(value).attr('data-email');
      addToBox(telephoneInput, 'telephone', function(response) {
        jQuery(value).append('<div class="detail-field telephone"><div>' + response + '</div></div>');
      });
      addToBox(faxInput, 'fax', function(response) {
        jQuery(value).append('<div class="detail-field fax"><div>' + response + '</div></div>');
      });
      addToBox(emailInput, 'email', function(response) {
        jQuery(value).append('<div class="detail-field email"><div>' + response + '</div></div>');
      });
      jQuery(value).removeAttr('data-telephone');
      jQuery(value).removeAttr('data-fax');
      jQuery(value).removeAttr('data-email');
    });
  }

  function addToBox(input, type, cb) {
    if(type=='telephone') {
      var href='tel:';
    } else if(type=='email') {
      var href='mailto:';
    } else {
      var href='fax:';
    }
    if(input!='') {
      input = input.replace(/ /g, '');
      var output = '';
      var telCtr=0;
      jQuery(input.split(',')).each(function(telIndex, telValue) {
        telCtr++;
        if(telCtr!=Object.keys(input.split(',')).length) {
         output += '<a href="' + href + telValue + '">' + telValue + '</a>, ';
       } else {
         output += '<a href="' + href + telValue + '">' + telValue + '</a>';
       }
      });
      cb(output);
    }
  }

  // add custom placeholder to dob field on the e-volunteer form as the plugin does not allow it
  jQuery(document).on('nfFormReady', function(e, layoutView) {
    if(jQuery('.ninja-forms-field.e-volunteer-dob').get().length>0) {
      jQuery('.ninja-forms-field.e-volunteer-dob').attr('placeholder', 'Your date of birth');
    }
  });

  // add custom emphasis class to masthead title for custom shortcode
  if(jQuery('.mast-head-wrapper.lg.left-mid-text').get().length>0) {
    if(jQuery('.mast-head-wrapper.lg.left-mid-text').find('.title').find('.bt-em-red').get().length==0) {
      var emCSV = jQuery('.mast-head-wrapper.lg.left-mid-text .title').attr('data-em');
      var emArray = jQuery.trim(emCSV).split(',');
      jQuery(emArray).each(function(index, value) {
        value = '<span class="bt-em-red">' + value + '</span>';
        jQuery('.mast-head-wrapper.lg.left-mid-text .title').html(jQuery('.mast-head-wrapper.lg.left-mid-text .title').text().replace(emArray[index], value));
      });
      jQuery('.mast-head-wrapper.lg.left-mid-text .title').removeAttr('data-em');
    }
    // lazyload gif
    jQuery('<img/>').attr('src', ajaxObj.themeURL + '/dist/images/temp/cinemagraph.gif').on('load', function() {
      jQuery(this).remove();
      // jQuery('.mast-head-wrapper.lg.left-mid-text .bg-img.replace-with-js').css('background-image', 'none');
      jQuery('.mast-head-wrapper.lg.left-mid-text .bg-img.replace-with-js').css({'background-image':'url(' + ajaxObj.themeURL + '/dist/images/temp/cinemagraph.gif)'});
    });
  }

  // add bootstrap styling to job form, make search text more human
  if(jQuery('.job_listings').get().length>0) {
    jQuery('.job_listings .job_filters .search_jobs .search_keywords #search_keywords').addClass('form-control');
    jQuery('.job_listings .job_filters .search_jobs .search_keywords #search_keywords').attr('placeholder', 'Search with keywords');
    jQuery('.job_listings .job_filters .search_jobs .search_location #search_location').addClass('form-control');
    jQuery('.job_listings .job_filters .search_jobs .search_location #search_location').attr('placeholder', 'Search for a location');
  }

  // convert wildcards to html
  if(jQuery('.has-wildcard').get().length>0) {
    jQuery('.has-wildcard').each(function(index, value) {
      var _this = jQuery(this);
      var wildcardClass = jQuery(value).attr('data-wildcard-class');
      var occuranceArray = jQuery(this).text().match(/\$(?:[^\$\\]|\\.)*\$/g);
      var HTMLToReplace = jQuery(this).html();
      jQuery(occuranceArray).each(function(index, value) {
        var occurance = value.replace(/\$/g, '');
        occurance = '<span class="' + wildcardClass + '">' + occurance + '</span>';
        jQuery(_this).html(HTMLToReplace.replace(value, occurance));
        HTMLToReplace = jQuery(_this).html();
      });
    });
  }

  // focus area CTA to slide to programs
  jQuery('[data-trigger=slide-to-id]').on('click', function(e) {
    e.preventDefault();
    var destination = jQuery(this).attr('data-destination');
    jQuery('html, body').animate({
      scrollTop: jQuery(destination).offset().top - 100
    }, 750);
  });

  if(ajaxObj.campaignPrimaryColor!='') {
    var cssInject = '';
    // mobile tab colour
    jQuery('meta[name=theme-color]').remove();
    jQuery('head').prepend('<meta name="theme-color" content="' + ajaxObj.campaignPrimaryColor + '">');
    // selection color
    cssInject += '::selection {color: ' + ajaxObj.campaignPrimaryColor + '}';
    // top header items
    cssInject += 'header.top-navigation .custom-nav .navbar-nav>li>a{box-shadow: inset 0 0 0 ' + ajaxObj.campaignPrimaryColor + ';-webkit-box-shadow: inset 0 0 0 ' + ajaxObj.campaignPrimaryColor + ';}'
    cssInject += 'header.top-navigation .custom-nav .navbar-nav>li>a:focus, header.top-navigation .custom-nav .navbar-nav>li>a:hover{box-shadow: inset 0 -3px 0 ' + ajaxObj.campaignPrimaryColor + ';-webkit-box-shadow: inset 0 -3px 0 ' + ajaxObj.campaignPrimaryColor + ';}'
    // top header sub items
    cssInject += 'header.top-navigation .custom-nav .dropdown .dropdown-menu>li{box-shadow: inset 0 0 0 ' + ajaxObj.campaignPrimaryColor + ';-webkit-box-shadow: inset 0 0 0 ' + ajaxObj.campaignPrimaryColor + ';}';
    cssInject += 'header.top-navigation .custom-nav .dropdown .dropdown-menu>li:focus, header.top-navigation .custom-nav .dropdown .dropdown-menu>li:hover{box-shadow: inset 3px 0 0 ' + ajaxObj.campaignPrimaryColor + ';-webkit-box-shadow: inset 3px 0 0 ' + ajaxObj.campaignPrimaryColor + ';}';
    // top header button
    cssInject += 'header.top-navigation .custom-nav .navbar-nav>li:last-child>a{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // all red dots
    cssInject += '.bt-em-red{color:' + ajaxObj.campaignPrimaryColor + '}';
    // all buttons
    cssInject += '.custom-btn{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // slick navigation on stat boxes
    cssInject += '.all-stats-wrapper.icon-n-data .navigation-wrapper .navigation.slick .slick-dots>li.slick-active>button{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // all section division lines
    cssInject += '.section-header .division{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // slick navigation bullet
    cssInject += '.section-header .top .navigation.slick .slick-dots>li.slick-active>button{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // blog separator
    cssInject += '.slick-wrapper.full-width-card-slider .slick-slide .slick-card .text-wrapper .meta-wrapper .separator{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // blog content
    cssInject += '.blog-post-content-wrapper p>a{box-shadow: inset 0 -0.25em 0 ' + ajaxObj.campaignPrimaryColor + ';-webkit-box-shadow: inset 0 -0.25em 0 ' + ajaxObj.campaignPrimaryColor + ';}';
    cssInject += '.blog-post-content-wrapper p>a:focus, .blog-post-content-wrapper p>a:hover{box-shadow: inset 0 -0.5em 0 ' + ajaxObj.campaignPrimaryColor + ';-webkit-box-shadow: inset 0 -0.5em 0 ' + ajaxObj.campaignPrimaryColor + ';}';
    cssInject += '.blog-post-content-wrapper .navigation.slick .slick-dots>li.slick-active>button{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // cta band cta
    cssInject += '.cta-band-wrapper .cta-band .cta{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // donate boxes
    cssInject += '.indented-box .icon{background-color:' + ajaxObj.campaignPrimaryColor + '}';
    // footer link hover
    cssInject += '.bottom-navigation.top #bottom-navigation #menu-bottom-navigation>li.menu-item .sub-menu>li.menu-item>a:focus, .bottom-navigation.top #bottom-navigation #menu-bottom-navigation>li.menu-item .sub-menu>li.menu-item>a:hover{color:' + ajaxObj.campaignPrimaryColor + '}';
    cssInject += '.bottom-navigation.bottom .left a:focus, .bottom-navigation.bottom .left a:hover{color:' + ajaxObj.campaignPrimaryColor + '}';
    cssInject += '.bottom-navigation.bottom .left .separator{background-color:' + ajaxObj.campaignPrimaryColor + '}'
    jQuery('body').append('<style id="campaign-specific-css">' + cssInject + '</style>');
  }

  // our work page map
  if(jQuery('#our-work-map').get().length > 0) {
    var data = JSON.parse(jQuery('#our-work-map').attr('data-info'));
    var map = new GMaps({
      div: '#our-work-map',
      lat: data[0].lat_long.split(',')[0],
      lng: data[0].lat_long.split(',')[1]
    });

    var marker = {};
    var infoWindow = {};

    jQuery(data).each(function(index, value) {
      infoWindow[value.lat_long.split(',')[0] + ', ' + value.lat_long.split(',')[1]] = new google.maps.InfoWindow({
        content: '<span class="map-marker-infowindow"><span class="title title-font">' + value.title + '<span class="bt-em-red">.</span></span><span class="description">' + value.description.replace(/\n/g,"<br/>") + '</span></span>'
      });
      marker[value.lat_long.split(',')[0] + ', ' + value.lat_long.split(',')[1]] = map.addMarker({
        lat: value.lat_long.split(',')[0],
        lng: value.lat_long.split(',')[1],
        infoWindow: {
          content: infoWindow[value.lat_long.split(',')[0] + ', ' + value.lat_long.split(',')[1]].content
        }
      });
    });

    map.fitZoom();

  }

  jQuery.fn.isInViewport = function() {
    var elementTop = jQuery(this).offset().top;
    var elementBottom = elementTop + jQuery(this).outerHeight();
    var viewportTop = jQuery(window).scrollTop();
    var viewportBottom = viewportTop + jQuery(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  if(jQuery('.infographic-wrapper').get().length > 0) {
    // add iframe src
    var flag = false;
    jQuery(document).on('scroll', function() {
      if(jQuery('.infographic-wrapper').isInViewport()) {
        if(!flag) {
          flag = true;
          jQuery('.infographic-wrapper').find('iframe').attr('src', ajaxObj.themeURL + '/infographic/index.html');
        }
      }
    });
  }

  // donate page js
  // other init
  jQuery('.page-template-page-template-donate .extra-functions-wrapper').on('click', '[data-trigger=other-amount]', function() {
    var action = jQuery(this).attr('data-action');
    var source = jQuery(this).attr('data-source');
    var from = jQuery(this).attr('data-from');
    if(action=='other') {
      jQuery(this).parent().parent().find('.range-slider .custom-form-group').slideDown().fadeIn();
      jQuery(this).text('Back?');
      jQuery(this).attr('data-action', 'slider');
      jQuery(this).parent().parent().find('.cta [data-trigger=donation_init]').attr('data-source', 'other');
      jQuery(this).parent().parent().find('.range-slider .slider.slider-horizontal').slideUp().fadeOut();
      jQuery(this).parent().parent().find('.range-slider').css('margin-top', 0);
      if(source=='one-time') {
        oneTimeAmountSlider.bootstrapSlider('disable');
      } else if(source=='monthly') {
        monthlyAmountSlider.bootstrapSlider('disable');
      }
      jQuery('.donate-form-wrapper .desc').fadeOut(function() {
        jQuery(this).text('Please enter the amount you would like to contribute.').fadeIn();
      });
    } else if(action=='slider') {
      jQuery(this).parent().parent().find('.range-slider .slider.slider-horizontal').slideDown().fadeIn();
      jQuery(this).text('Change Amount?');
      jQuery(this).attr('data-action', 'other');
      jQuery(this).parent().parent().find('.cta [data-trigger=donation_init]').attr('data-source', 'slider');
      jQuery(this).parent().parent().find('.range-slider .custom-form-group').slideUp().fadeOut();
      jQuery(this).parent().parent().find('.range-slider').css('margin-top', '20px');
      if(source=='one-time') {
        oneTimeAmountSlider.bootstrapSlider('enable');
        updateDonationBoxDOM(oneTimeAmountSlider.bootstrapSlider('getValue'), source, from);
      } else if(source=='monthly') {
        monthlyAmountSlider.bootstrapSlider('enable');
        updateDonationBoxDOM(monthlyAmountSlider.bootstrapSlider('getValue'), source, from);
      }
    }
  });

  // change currency
  jQuery('.page-template-page-template-donate .extra-functions-wrapper').on('click', '[data-trigger=change-currency]', function() {
    var chosenCurrency = jQuery(this).attr('data-currency');
    var currentCurrency = jQuery(this).parent().parent().parent().parent().parent().find('.cta button').attr('data-current-currency');
    if(chosenCurrency==currentCurrency) {
      return;
    } else {

      switch (chosenCurrency) {
        case 'inr':

          jQuery(this).parent().parent().parent().parent().parent().find('.cta button').attr('data-current-currency', 'inr');

          var source = jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').attr('data-source');
          var from = jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').attr('data-from');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .slider.slider-horizontal').slideDown().fadeIn();
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').attr('data-action', 'other');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.cta [data-trigger=donation_init]').attr('data-source', 'slider');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .custom-form-group').slideUp().fadeOut();
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider').css('margin-top', '20px');
          if(source=='one-time') {
            oneTimeAmountSlider.bootstrapSlider('enable');
            updateDonationBoxDOM(oneTimeAmountSlider.bootstrapSlider('getValue'), source, from);
          } else if(source=='monthly') {
            monthlyAmountSlider.bootstrapSlider('enable');
            updateDonationBoxDOM(monthlyAmountSlider.bootstrapSlider('getValue'), source, from);
          }

          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').text('Change Amount?').css({'opacity': 1, 'z-index': 1});
          jQuery(this).parent().parent().parent().find('#currency-selector').html('Change Currency (INR)<span class="caret"></span>');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .custom-form-group .input-group .input-group-addon .fa').removeClass('fa-usd fa-eur').addClass('fa-inr');
          jQuery(this).parent().parent().find('li').removeClass('disabled');
          jQuery(this).parent().addClass('disabled');

          break;

        case 'usd':

          jQuery(this).parent().parent().parent().parent().parent().find('.cta button').attr('data-current-currency', 'usd');

          var source = jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').attr('data-source');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .custom-form-group').slideDown().fadeIn();
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').attr('data-action', 'slider');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.cta [data-trigger=donation_init]').attr('data-source', 'other');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .slider.slider-horizontal').slideUp().fadeOut();
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider').css('margin-top', 0);
          if(source=='one-time') {
            oneTimeAmountSlider.bootstrapSlider('disable');
          } else if(source=='monthly') {
            monthlyAmountSlider.bootstrapSlider('disable');
          }
          jQuery('.donate-form-wrapper .desc').fadeOut(function() {
            jQuery(this).text('Please enter the amount you would like to contribute.').fadeIn();
          });

          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').css({'opacity': 0, 'z-index': -1});
          jQuery(this).parent().parent().parent().find('#currency-selector').html('Change Currency (USD)<span class="caret"></span>');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .custom-form-group .input-group .input-group-addon .fa').removeClass('fa-inr fa-eur').addClass('fa-usd');
          jQuery(this).parent().parent().find('li').removeClass('disabled');
          jQuery(this).parent().addClass('disabled');

          break;

        case 'eur':

          jQuery(this).parent().parent().parent().parent().parent().find('.cta button').attr('data-current-currency', 'eur');

          var source = jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').attr('data-source');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .custom-form-group').slideDown().fadeIn();
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').attr('data-action', 'slider');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.cta [data-trigger=donation_init]').attr('data-source', 'other');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .slider.slider-horizontal').slideUp().fadeOut();
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider').css('margin-top', 0);
          if(source=='one-time') {
            oneTimeAmountSlider.bootstrapSlider('disable');
          } else if(source=='monthly') {
            monthlyAmountSlider.bootstrapSlider('disable');
          }
          jQuery('.donate-form-wrapper .desc').fadeOut(function() {
            jQuery(this).text('Please enter the amount you would like to contribute.').fadeIn();
          });

          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').css({'opacity': 0, 'z-index': -1});
          jQuery(this).parent().parent().parent().find('#currency-selector').html('Change Currency (EUR)<span class="caret"></span>');
          jQuery(this).parent().parent().parent().parent().find('[data-trigger=other-amount]').parent().parent().find('.range-slider .custom-form-group .input-group .input-group-addon .fa').removeClass('fa-inr fa-usd').addClass('fa-eur');
          jQuery(this).parent().parent().find('li').removeClass('disabled');
          jQuery(this).parent().addClass('disabled');

          break;
      }

    }

    // console.log(chosenCurrency);
  });

  // on tab change
  jQuery('.page-template-page-template-donate .donate-form-wrapper a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    if(jQuery(e.target).attr('aria-controls')=='one-time') {
      if(oneTimeAmountSlider.bootstrapSlider('isEnabled')) {
        oneTimeAmountSlider.bootstrapSlider('refresh');
        updateDonationBoxDOM(oneTimeAmountSlider.bootstrapSlider('getValue'), 'one-time', jQuery('.donate-form-wrapper').find('[data-from]').attr('data-from'));
      } else {
        jQuery('.donate-form-wrapper .desc').fadeOut(function() {
          jQuery(this).text('Please enter the amount you would like to contribute.').fadeIn();
        });
      }
    } else if(jQuery(e.target).attr('aria-controls')=='monthly') {
      if(monthlyAmountSlider.bootstrapSlider('isEnabled')) {
        monthlyAmountSlider.bootstrapSlider('refresh');
        updateDonationBoxDOM(monthlyAmountSlider.bootstrapSlider('getValue'), 'monthly', jQuery('.donate-form-wrapper').find('[data-from]').attr('data-from'));
      } else {
        jQuery('.donate-form-wrapper .desc').fadeOut(function() {
          jQuery(this).text('Please enter the amount you would like to contribute.').fadeIn();
        });
      }
    }
  });

  // one-time donation js
  if(jQuery('.range-slider.one-time #one-time-variable-cost-id').get().length > 0) {
    var rawOneTimeVariablesCosts = JSON.parse(jQuery('.range-slider.one-time #one-time-variable-cost-id').attr('data-array'));
    var cleanOneTimeVariablesCosts = {};
    for(var key in rawOneTimeVariablesCosts) {
      cleanOneTimeVariablesCosts[Math.round(rawOneTimeVariablesCosts[key])] = key;
    }
    jQuery('.range-slider.one-time #one-time-variable-cost-id').attr('value', cleanOneTimeVariablesCosts[Object.keys(cleanOneTimeVariablesCosts)[0]]);
    var justOneTimeCosts = [];
    for(var key in cleanOneTimeVariablesCosts) {
      justOneTimeCosts.push(key);
    }
    var initialValue, finalValue = null;
    var oneTimeAmountSlider = jQuery('.range-slider.one-time input.slider[name=one-time-amount]').bootstrapSlider({
      id: 'one-time-donate-amount',
      min: Object.keys(cleanOneTimeVariablesCosts)[0],
      max: Object.keys(cleanOneTimeVariablesCosts)[Object.keys(cleanOneTimeVariablesCosts).length-1],
      // step: 3000,
      // value: 3000,
      tooltip: 'always',
      ticks: justOneTimeCosts
    }).on('change', function(e){
      initialValue = e.value.oldValue;
      finalValue = e.value.newValue;
      skipStep(initialValue, finalValue, cleanOneTimeVariablesCosts, 'one-time', function() {
        jQuery('.range-slider.one-time #one-time-variable-cost-id').attr('value', cleanOneTimeVariablesCosts[oneTimeAmountSlider.bootstrapSlider('getValue')]);
        updateDonationBoxDOM(oneTimeAmountSlider.bootstrapSlider('getValue'), 'one-time', jQuery('.donate-form-wrapper').find('[data-from]').attr('data-from'));
      });
    });
  }

  // monthly donation js
  if(jQuery('.range-slider.monthly #monthly-variable-cost-id').get().length > 0) {
    var rawMonthlyVariablesCosts = JSON.parse(jQuery('.range-slider.monthly #monthly-variable-cost-id').attr('data-array'));
    var cleanMonthlyVariablesCosts = {};
    for(var key in rawMonthlyVariablesCosts) {
      cleanMonthlyVariablesCosts[Math.round(rawMonthlyVariablesCosts[key])] = key;
    }
    jQuery('.range-slider.monthly #monthly-variable-cost-id').attr('value', cleanMonthlyVariablesCosts[500]);
    var justMonthlyCosts = [];
    for(var key in cleanMonthlyVariablesCosts) {
      justMonthlyCosts.push(key);
    }
    var initialValue, finalValue = null;
    var monthlyAmountSlider = jQuery('.range-slider.monthly input.slider[name=monthly-amount]').bootstrapSlider({
      id: 'monthly-donate-amount',
      min: Object.keys(cleanMonthlyVariablesCosts)[0],
      max: Object.keys(cleanMonthlyVariablesCosts)[Object.keys(cleanMonthlyVariablesCosts).length-1],
      // step: 250,
      // value: 500,
      tooltip: 'always',
      ticks: justMonthlyCosts
    }).on('change', function(e){
      initialValue = e.value.oldValue;
      finalValue = e.value.newValue;
      skipStep(initialValue, finalValue, cleanMonthlyVariablesCosts, 'monthly', function() {
        jQuery('.range-slider.monthly #monthly-variable-cost-id').attr('value', cleanMonthlyVariablesCosts[monthlyAmountSlider.bootstrapSlider('getValue')]);
        updateDonationBoxDOM(monthlyAmountSlider.bootstrapSlider('getValue'), 'monthly', jQuery('.donate-form-wrapper').find('[data-from]').attr('data-from'));
      });
    });
  }

  function updateDonationBoxDOM(amount, type, from) {
    var tagline;
    if(type=="one-time" && from=="donate") {
      switch (amount) {
        case 3000:
            tagline = 'Train 10 anganwadi workers on adolescent girls\' health issues.';
          break;
        case 6000:
            tagline = '5 children are part of a school programme on gender issues.';
          break;
        case 9000:
            tagline = 'Training material for gender sensitisation of one school.';
          break;
        case 12000:
            tagline = 'Train 40 anganwadi workers on adolescent girls\' health issues.';
          break;
        case 24000:
            tagline = 'Sponsor a video van trip to one village.';
          break;
        case 36000:
            tagline = 'Run a mini local campaign for community for a relevant gender issue.';
          break;
        default:
          tagline = 'Train 10 anganwadi workers on adolescent girls\' health issues.';
      }
    } else if(type=="monthly" && from=="donate") {
      switch (amount) {
        case 500:
            tagline = '5 children are part of a school programme on gender issues.';
          break;
        case 750:
            tagline = 'Training material for gender sensitisation of one school.';
          break;
        case 1000:
            tagline = 'Train 40 anganwadi workers on adolescent girls\' health issues.';
          break;
        case 2000:
            tagline = 'Sponsor a video van trip to one village.';
          break;
        case 3000:
            tagline = 'Run a mini local campaign for community for a relevant gender issue.';
          break;
        default:
          tagline = '5 children are part of a school programme on gender issues';
      }
    } else if(type=="one-time" && from=="team-change") {
      switch (amount) {
        case 3600:
            tagline = 'Helps us fund our school programme to change behaviours and  attitudes of teenagers.';
          break;
        case 6000:
            tagline = 'Helps us run digital campaigns to build dialogue on critical issues.';
          break;
        case 8400:
            tagline = 'Helps us deliver trainings that empower young people';
          break;
        case 12000:
            tagline = 'Helps us work at the village level to build gender sensitivity in the community.';
          break;
        default:
          tagline = 'Helps us fund our school programme to change behaviours and  attitudes of teenagers.';
      }
    } else if(type=="monthly" && from=="team-change") {
      switch (amount) {
        case 300:
            tagline = 'Helps us fund our school programme to change behaviours and  attitudes of teenagers.';
          break;
        case 500:
            tagline = 'Helps us run digital campaigns to build dialogue on critical issues.';
          break;
        case 700:
            tagline = 'Helps us deliver trainings that empower young people';
          break;
        case 1000:
            tagline = 'Helps us work at the village level to build gender sensitivity in the community.';
          break;
        default:
          tagline = 'Helps us fund our school programme to change behaviours and  attitudes of teenagers.';
      }
    }
    jQuery('.donate-form-wrapper .desc').fadeOut(function() {
      jQuery(this).text(tagline).fadeIn();
    });
  }

  function skipStep(initialValue, finalValue, valuesObj, type, cb) {
    var amountValues = [];
    jQuery.each(valuesObj, function(index, value) {
      amountValues.push(parseInt(index));
    });
    amountValues.sort(sortNumber);
    if(type=='one-time') {
      var amountSlider = oneTimeAmountSlider;
    } else if(type=='monthly') {
      var amountSlider = monthlyAmountSlider;
    }
    if((initialValue<finalValue) && (jQuery.inArray(finalValue, amountValues))==-1) {
      // increase
      var nextValue = amountValues[jQuery.inArray(initialValue, amountValues)+1];
      amountSlider.bootstrapSlider('setValue', nextValue);
      updateDonationBoxDOM(nextValue, type, jQuery('.donate-form-wrapper').find('[data-from]').attr('data-from'));
    } else if((initialValue>finalValue) && (jQuery.inArray(finalValue, amountValues))==-1) {
      // decrease
      var prevValue = amountValues[jQuery.inArray(initialValue, amountValues)-1];
      amountSlider.bootstrapSlider('setValue', prevValue);
      updateDonationBoxDOM(prevValue, type, jQuery('.donate-form-wrapper').find('[data-from]').attr('data-from'));
    }
    cb(true);
  }

  function sortNumber(a,b) {
    return a - b;
  }

  jQuery('[data-trigger=donation_init]').click(function() {
    jQuery(this).append('&nbsp;&nbsp;<span class="fa fa-circle-o-notch fa-spin"></span>');
    jQuery(this).attr('disabled', 'disabled');
    var validated = false;
    var type = jQuery(this).attr('data-type');
    var source = jQuery(this).attr('data-source');
    var from = jQuery('.donate-form-wrapper').find('[data-from]').attr('data-from');
    var currency = jQuery(this).attr('data-current-currency');
    if((type=='one-time') && (source=='slider')) {
      var amount = oneTimeAmountSlider.bootstrapSlider('getValue');
      var variationId = jQuery('.range-slider.one-time #one-time-variable-cost-id').val();
      validated = true;
    } else if((type=='monthly') && (source=='slider')) {
      var amount = monthlyAmountSlider.bootstrapSlider('getValue');
      var variationId = jQuery('.range-slider.monthly #monthly-variable-cost-id').val();
      validated = true;
    } else if((source=='other')) {
      var amount, rawAmount = jQuery(this).parent().parent().find('.range-slider .other-amount-wrapper input[type=number]').val().trim();
      var exchangeRate = null;
      switch (currency) {
        case 'inr':
          amount = rawAmount;
          break;
        case 'usd':
          jQuery.ajax({
            url: 'https://api.exchangeratesapi.io/latest?base=INR',
            async: false,
            dataType: 'json'
          }).done(function(data) {
            exchangeRate = 1/data.rates.USD;
            amount = rawAmount * exchangeRate;
          });
          break;
        case 'eur':
          jQuery.ajax({
            url: 'https://api.exchangeratesapi.io/latest?base=INR',
            async: false,
            dataType: 'json'
          }).done(function(data) {
            exchangeRate = 1/data.rates.EUR;
            amount = rawAmount * exchangeRate;
          });
          break;
      }
      if(amount<=0) {
        var errorMessage = 'Oops! The donation amount needs to be greater than zero.';
      } else if(amount>2000000) {
        var errorMessage = 'Oops! The donation amount needs to be less than INR 20 Lakhs.';
      } else {
        validated = true;
      }
      var variationId = null;
    }
    if(validated) {
      jQuery.ajax({
        url: ajaxObj.ajaxUrl,
        type: 'POST',
        data: {
          action: 'process_donation',
          amount: amount,
          variation_id: variationId,
          source: source,
          type: type,
          from: from
        },
        success: function(data) {
          if(data.status) {
            window.location.replace(data.redirect);
          }
        }
      });
    } else {
      jQuery(this).text('Donate');
      jQuery(this).removeAttr('disabled');
      jQuery(this).parent().parent().find('.help').append('&nbsp;&nbsp;' + errorMessage).slideUp().fadeIn();
    }
  });

  jQuery('.search-toggle').on('click', function() {
    if(jQuery('.search-form-wrapper').hasClass('closed')) {
      jQuery('body').css('overflow', 'hidden');
      jQuery('.search-form-wrapper').removeClass('closed animated fadeOut');
      jQuery('.search-form-wrapper').addClass('open animated fadeIn');
    }
  });

  jQuery('.search-form-wrapper > a.close-btn').click(function() {
    if(jQuery('.search-form-wrapper').hasClass('open')) {
      jQuery('body').css('overflow', 'auto');
      jQuery('.search-form-wrapper').removeClass('open animated fadeIn');
      jQuery('.search-form-wrapper').addClass('closed animated fadeOut');
    }
  });

  jQuery('[data-trigger=download-purpose]').on('click', function() {
    var url = jQuery(this).attr('data-url');
    jQuery('.download-purpose-modal .modal-footer > a').attr('href', url);

    jQuery(document).on('nfFormSubmitResponse', function() {
      var win = window.open(url, '_blank');
      if (win) {
        win.focus();
      } else {
        alert('Please allow popups for this website.');
      }
    });

  });

});
